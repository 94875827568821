import type { PageFooterWidgetConfig } from './type';

export const PAGE_FOOTER_CONFIG: PageFooterWidgetConfig = {
    linkLists: [
        {
            title: 'frontend.footer.links.title-about-us',
            links: [
                {
                    label: 'frontend.footer.about-us.about-us',
                    href: '/konto/korzysci/',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'about_us',
                    },
                },
                {
                    label: 'frontend.footer.about-us.press-office',
                    href: 'https://media.otodom.pl/',
                    target: '_blank',
                    rel: 'nofollow',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'press_office',
                    },
                },
                {
                    label: 'frontend.footer.about-us.career',
                    href: 'https://www.olxgroup.com/',
                    target: '_blank',
                    rel: 'nofollow',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'career',
                    },
                },
                {
                    label: 'frontend.footer.about-us.help-center',
                    href: 'https://pomoc.otodom.pl/otodomhelpcenter/s/',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'help_center',
                    },
                },
                {
                    label: 'frontend.footer.about-us.digital-service-act',
                    href: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/EU-Digital-Services-Act-DSA',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'dsa_footer',
                    },
                },
                {
                    label: 'frontend.footer.about-us.finance',
                    href: '/[lang]/finanse',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'finance_and_mortgages',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-professionals',
            links: [
                {
                    label: 'frontend.footer.professionals.price-list',
                    href: '/[lang]/cennik',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'price_list',
                    },
                },
                {
                    label: 'frontend.footer.professionals.sell-with',
                    href: 'https://szo.otodom.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'sell_with_otodom',
                    },
                },
                {
                    label: 'frontend.footer.professionals.agent-pro',
                    href: 'https://agentpro.otodom.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'agent_pro_tools',
                    },
                },
                {
                    label: 'frontend.footer.professionals.academy-agent-pro',
                    href: 'https://agentpro.otodom.pl/akademia',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'agent_pro_academy',
                    },
                },
                {
                    label: 'frontend.footer.professionals.tenant-certificate',
                    href: 'https://certyfikatnajemcy.otodom.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'tenant_certificate',
                    },
                },
                {
                    label: 'frontend.footer.professionals.tool-for-offices',
                    href: 'https://developer.olxgroup.com/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'tools_for_offices',
                    },
                },
                {
                    label: 'frontend.footer.professional.analytics',
                    href: 'https://analytics.otodom.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'free_price_data',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-knowledge-hub',
            links: [
                {
                    label: 'frontend.footer.knowledge-hub.market-reports',
                    href: '/wiadomosci/pobierz/raporty',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'market_reports',
                    },
                },
                {
                    label: 'frontend.footer.knowledge-hub.market-situation',
                    href: '/wiadomosci/dane/sytuacja-na-rynku',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'market_situation',
                    },
                },
                {
                    label: 'frontend.footer.knowledge-hub.lighthouse',
                    href: '/wiadomosci/pobierz/magazyn-lighthouse',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'otodom_lighthouse_magazine',
                    },
                },
                {
                    label: 'frontend.footer.knowledge-hub.free-price-data',
                    href: '/wiadomosci/dane/otodom-analytics ',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'free_price_data_blog',
                    },
                },
                {
                    label: 'frontend.footer.knowledge-hub.articles-and-tips',
                    href: '/wiadomosci/',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'articles_and_tips',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-olx-group',
            links: [
                {
                    label: 'frontend.footer.olx-group.olx',
                    href: 'https://www.olx.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'olx.pl',
                    },
                },
                {
                    label: 'frontend.footer.olx-group.otomoto',
                    href: 'https://www.otomoto.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'otomoto.pl',
                    },
                },
                {
                    label: 'frontend.footer.olx-group.fixly',
                    href: 'https://fixly.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'fixly.pl',
                    },
                },
                {
                    label: 'frontend.footer.olx-group.obido',
                    href: 'https://obido.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'obido.pl',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-sitemap',
            links: [
                {
                    label: 'frontend.footer.sitemap.sitemap',
                    href: '/[lang]/mapa-kategorii',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_category',
                    },
                },
                {
                    label: 'frontend.footer.sitemap.location-map',
                    href: '/[lang]/mapa-kategorii/miejscowosci/mieszkanie',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_location',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-contact',
            links: [
                {
                    label: 'frontend.footer.contact.custom-service',
                    href: 'https://pomoc.otodom.pl/otodomhelpcenter/s/contactsupport',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'contact_support',
                    },
                },
                {
                    label: 'frontend.footer.contact.sales-department',
                    href: 'https://partner.otodom.pl/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'sales_department',
                    },
                },
            ],
        },
    ],
    mobileAppsMarkets: {
        googlePlay: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/google_play.svg',
                width: 120,
                height: 40,
            },
            appUrl: 'https://play.google.com/store/apps/details?id=pl.otodom&amp;referrer=utm_source%3Dotodom.pl%26utm_medium%3Dcpc%26utm_campaign%3Dandroid-app-footer',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'google_play',
            },
        },
        appStore: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/app_store.svg',
                width: 131,
                height: 40,
            },
            appUrl: 'https://apps.apple.com/pl/app/otodom/id830421016',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'app_store',
            },
        },
    },
    socialLinks: [
        {
            icon: '/images/pageFooter/socialIcons/facebook.svg',
            href: 'http://www.facebook.com/Otodom',
            title: 'frontend.footer.links.social-facebook',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'facebook',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/youtube.svg',
            href: 'http://www.youtube.com/channel/UCRp8W7MwtrQPMlAHt5FbQrw',
            title: 'frontend.footer.links.social-youtube',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'youtube',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/linkedin.svg',
            href: 'https://www.linkedin.com/company/otodom/',
            title: 'frontend.footer.links.social-linkedin',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'linkedin',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/instagram.svg',
            href: 'http://instagram.com/otodom',
            title: 'frontend.footer.links.social-instagram',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'instagram',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/spotify.svg',
            href: 'https://open.spotify.com/show/2aY7zNB0D9iofh7QzmhJ3g',
            title: 'frontend.footer.links.social-spotify',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'spotify',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/twitter.svg',
            href: 'https://twitter.com/otodom/',
            title: 'frontend.footer.links.social-twitter',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'twitter',
            },
        },
    ],
    consentLinks: [
        {
            label: 'frontend.footer.consent.terms-of-cooperation',
            href: 'https://pomoc.otodom.pl/otodomhelpcenter/s/topic/0TO09000000kfiMGAQ/',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'terms_of_cooperation',
            },
        },
        {
            label: 'frontend.footer.consent.privacy-policy',
            href: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1hJCAQ',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'privacy_policy',
            },
        },
        {
            label: 'frontend.footer.consent.cookie-settings',
            button: 'cookieButton',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'cookie_settings',
            },
        },
    ],
    logo: {
        src: '/images/logo/otodom_logo_2021_black.svg',
        width: 154,
        height: 30,
    },
};
